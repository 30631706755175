'use client';

import { LOCALES } from 'src/constants.cjs';
import { getPathnameWithoutLocale } from '@mentimeter/i18n';
import { usePathname } from '@mentimeter/next-navigation';
import { useReportWebVitals } from 'next/web-vitals';
import { sendDistribution } from '@mentimeter/errors/sendMetric';

const PERFORMANCE_METRICS = ['FCP', 'LCP', 'CLS', 'TTFB'];
const METRICS_PATHS = ['/', '/plans'];

export function WebVitals() {
  const currentPath = usePathname();
  const pathnameWithoutLocale = getPathnameWithoutLocale(currentPath, LOCALES);

  useReportWebVitals((metric) => {
    const envName = globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME'];

    if (!METRICS_PATHS.includes(pathnameWithoutLocale)) {
      return;
    }

    const isDev = envName === 'dev';
    // 100% stage, 10% prod
    const isSample = envName !== 'prod' || Math.random() < 0.1;
    const isMetricName = PERFORMANCE_METRICS.includes(metric.name);
    // only when going directly to the page (not "reload", "back_forward", or "prerender")
    const isMetricType = metric.navigationType === 'navigate';

    if (!isDev && isSample && isMetricName && isMetricType) {
      const value = Math.trunc(metric.value);
      const metricName: `mmjs.${string}` = `mmjs.${metric.name.toLowerCase()}`;
      sendDistribution({
        name: metricName,
        value,
        tags: [
          `route:${currentPath}`,
          `env:${globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME']}`,
          `service:${globalThis.__mentimeterEnv['NEXT_PUBLIC_SERVICE_NAME']}`,
        ],
      });
    }
  });

  return null;
}
