import Cookies, { cookiesAreEnabled } from '@mentimeter/cookies';
import { LAST_TOUCH_KEY, FIRST_TOUCH_KEY } from './utmKeys';
import { campaignParams } from './campaignParams';
import { COOKIE_EXPIRATION_DAYS } from './constants';

export const parseAndStoreUtmParams = (url: string): void => {
  if (!cookiesAreEnabled()) return;
  const utmParams = campaignParams(url, document?.referrer);
  if (!utmParams) return;

  if (!Cookies.get(LAST_TOUCH_KEY)) {
    // set utm_first cookie only if it's not been set before
    // set it for session only, so it will be overwritten after users close web browser (start a new session)
    // this behavior is crucial since users can change the flow of signup (for example first enter through google and a week after through bing)
    Cookies.setJSON({
      type: 'performance',
      name: LAST_TOUCH_KEY,
      value: utmParams.lastParams,
    });
  }

  if (!Cookies.get(FIRST_TOUCH_KEY)) {
    Cookies.setJSON({
      type: 'performance',
      name: FIRST_TOUCH_KEY,
      value: utmParams.firstParams,
      options: {
        expires: COOKIE_EXPIRATION_DAYS,
      },
    });
  }
};
