/* eslint-disable menti-react/filename-convention--jsx */
'use client';

import * as React from 'react';

interface SEEContextT {
  currentConnections: Map<string, EventSource>;
  createConnection: (
    url: string,
    options?: EventSourceInit,
  ) => void | EventSource;
  getConnection: (url: string) => EventSource | undefined;
  removeConnection: (url: string) => void;
}

export const SSEContext = React.createContext<SEEContextT | null>(null);

/**
 * @param {React.ReactNode} children - The children nodes.
 */
export const SSEProvider = ({ children }: { children: React.ReactNode }) => {
  const connections = React.useRef(new Map<string, EventSource>());

  const createConnection = (url: string, options: EventSourceInit = {}) => {
    if (typeof window === 'undefined') return;

    if (connections.current.has(url)) return getConnection(url);

    const connection = new EventSource(url, options);

    connections.current.set(url, connection);
    return connection;
  };

  const getConnection = (url: string) => connections.current.get(url);

  const removeConnection = (url: string) => {
    if (!connections.current.has(url)) return;

    const connection = getConnection(url);
    if (connection) {
      connection.close();
    }
    connections.current.delete(url);
  };

  return (
    <SSEContext.Provider
      value={{
        currentConnections: connections.current,
        createConnection,
        getConnection,
        removeConnection,
      }}
    >
      {children}
    </SSEContext.Provider>
  );
};
